import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "development-documentation"
    }}>{`Development documentation`}</h1>
    <h2 {...{
      "id": "a-few-tips"
    }}>{`A few tips`}</h2>
    <ul>
      <li parentName="ul">{`Merge to master will trigger a build and deploy to production as part of the CircleCI pipeline`}
        <ul parentName="li">
          <li parentName="ul">{`this is also done periodically three times a day (see Readme for more info`}</li>
        </ul>
      </li>
      <li parentName="ul">{`If you add `}<inlineCode parentName="li">{`-staging`}</inlineCode>{` to the branch name, it will trigger a build and deploy to Staging (our test environment, that is `}<a parentName="li" {...{
          "href": "https://developer.staging.entur.org"
        }}>{`https://developer.staging.entur.org`}</a>{`) as part of the CircleCI pipeline when approving the stage in CircleCI`}
        <ul parentName="li">
          <li parentName="ul">{`it is also possible to deploy the current branch to Staging by running `}<inlineCode parentName="li">{`npm run deploy:staging`}</inlineCode>{` locally`}</li>
          <li parentName="ul">{`you may also deploy to a separate Staging environment by running `}<inlineCode parentName="li">{`firebase hosting:channel:deploy <name-of-feature> -P staging`}</inlineCode>{` locally`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "vulnerabilities"
    }}>{`Vulnerabilities`}</h2>
    <ul>
      <li parentName="ul">{`If new vulnerabilities are found in the project (when running `}<inlineCode parentName="li">{`npm run audit-ci`}</inlineCode>{` from CircleCI), they should be fixed as soon as possible. If it is not possible to fix them, they should be added to the whitelist in the `}<inlineCode parentName="li">{`audit-ci.json5`}</inlineCode>{` file`}
        <ul parentName="li">
          <li parentName="ul">{`this is checked on every build and periodically every Sunday around midnight`}
            <ul parentName="li">
              <li parentName="ul">{`Failing jobs are posted to #team-ep-circleci on Slack`}</li>
            </ul>
          </li>
          <li parentName="ul">{`How to fix vulnerabilities:`}
            <ul parentName="li">
              <li parentName="ul">{`Run `}<inlineCode parentName="li">{`npm run audit-ci`}</inlineCode>{` to see the vulnerabilities (alternatively `}<inlineCode parentName="li">{`npm audit`}</inlineCode>{` or see the result from the CircleCI build)`}</li>
              <li parentName="ul">{`Run `}<inlineCode parentName="li">{`npm ls <name of dependency`}</inlineCode>{` to see which dependencies are using the vulnerable dependency`}</li>
              <li parentName="ul">{`Run `}<inlineCode parentName="li">{`npm update <name of dependency>`}</inlineCode>{` to attempt to update to a patched version, which will also work with transitive dependencies`}</li>
              <li parentName="ul">{`If the above does not work, you may have to update the dependency in the package.json file manually in the `}<inlineCode parentName="li">{`overrides`}</inlineCode>{` section`}</li>
              <li parentName="ul">{`If no patched version is available / it is not possible to update the dependency, add the vulnerability to the whitelist in the `}<inlineCode parentName="li">{`audit-ci.json5`}</inlineCode>{` file`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"></li>
    </ul>
    <h2 {...{
      "id": "api-documentation-in-other-repos-which-is-a-part-of-developerenturorg"
    }}>{`Api Documentation in other repos which is a part of developer.entur.org`}</h2>
    <ul>
      <li parentName="ul">{`Teams generally keep the api documentation in the same repo as their code`}</li>
      <li parentName="ul">{`The api documentation is published to the entur-docs GCP bucket using a CircleCI orb (entur-docs)`}
        <ul parentName="li">
          <li parentName="ul">{`The documentation is then made available at `}<a parentName="li" {...{
              "href": "https://developer.entur.org"
            }}>{`https://developer.entur.org`}</a>{` when deploying the Developer Portal to production`}</li>
          <li parentName="ul">{`The orb is configured in the `}<inlineCode parentName="li">{`.circleci/config.yml`}</inlineCode>{` file`}</li>
          <li parentName="ul">{`If the orb is configured with the `}<inlineCode parentName="li">{`replace-all`}</inlineCode>{` flag set to `}<inlineCode parentName="li">{`false`}</inlineCode>{`, any files that are removed will have to be removed manually in the GCP bucket by Team Partner`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      